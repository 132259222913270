<div *blockUI="'signup-data-details'">
    <div class="page-container">
        <div class="col-sm-12">
            <form action="" (keydown.enter)="$event.preventDefault()" class="form" [formGroup]="signupForm">
                <div class="kt-portlet">
                    <div class="kt-portlet__body sign-page-container">
                        <div data-bs-spy="scroll" data-bs-target="#navbar-example2" data-bs-offset="0"
                            class="scrollspy-example" tabindex="0">
                            <div class="row">

                                <div class="col-lg-12">

                                    <div class="headerSection">
                                        <div class="row">
                                            <div class="col-lg-2">
                                                <div class="row">
                                                    <span (click)="backBtn()">
                                                        <i class="backImg fa fa-arrow-circle-left"></i>
                                                    </span>
                                                </div>
                                            </div>
                                            <div class="col-lg-3">
                                                <div class="headerImage">
                                                    <div class="imagecss">
                                                        <img class="headerImageStyle"
                                                            src="assets/images/Nutrition Manual Across the Continuum of Care Final.png">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-6">
                                                <div class="row">
                                                    <h1 class="title">New Facility Registration</h1>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="entrySection">
                                        <div>
                                            <div id="fName" style="display:flex;">
                                                <span class="text-danger star">*</span>
                                                <div class="inputContainer">
                                                    <div class="row">
                                                        <input type="text" class="input" formControlName="firstName"
                                                            (input)="validFirstName($event)" placeholder="First Name"
                                                            #autoFocus required />

                                                        <label for="" class="label">First Name</label>
                                                    </div>

                                                </div>

                                            </div>
                                            <span
                                                *ngIf="formSubmit && (signupForm.get('firstName')?.value==='' || invalidFname)"
                                                class="requiresLabel">
                                                This field is required.
                                            </span>
                                        </div>
                                        <div>
                                            <div id="lName" style="display:flex;">
                                                <span class="text-danger star">*</span>

                                                <div class="inputContainer">
                                                    <div class="row">
                                                        <input type="text" class="input" formControlName="lastName"
                                                            (input)="validLasttName($event)" placeholder="Last Name"
                                                            #autoFocus required />

                                                        <label for="" class="label">Last Name</label>
                                                    </div>

                                                </div>
                                            </div>
                                            <span
                                                *ngIf="formSubmit && (signupForm.get('lastName')?.value==='' || invalidLastName)"
                                                class="requiresLabel">
                                                This field is required.
                                            </span>
                                        </div>
                                        <div>
                                            <div id="email" style="display:flex;">
                                                <span class="text-danger star">*</span>
                                                <div class="inputContainer">
                                                    <div class="row">
                                                        <input type="text" class="input" (input)="emptyField()"
                                                            (blur)="compareEmail()" formControlName="emailId"
                                                            placeholder="Email Address" #autoFocus required />
                                                        <label for="" class="label">Email Address</label>
                                                        <!-- <i class="fa fa-close close" aria-hidden="true"></i> -->
                                                    </div>

                                                </div>
                                            </div>
                                            <span *ngIf="formSubmit && signupForm.get('emailId')?.value===''"
                                                class="requiresLabel">
                                                This field is required.
                                            </span>
                                            <span
                                                *ngIf="signupForm.controls.emailId.invalid &&  signupForm.controls.emailId.touched">
                                                <span class="msg"
                                                    *ngIf="signupForm.controls.emailId.errors?.['emailValidation'] && signupForm.get('emailId')?.value!='' && signupForm.get('emailId')?.value!=null"
                                                    [innerHtml]="emailErrMsg">

                                                </span>
                                            </span>
                                        </div>
                                        <div>

                                            <div id="reEnterEmailId" style="display:flex;">
                                                <span class="text-danger star">*</span>
                                                <div class="inputContainer">
                                                    <div class="row">
                                                        <input type="text" class="input" formControlName="emailId2"
                                                            (input)="emptyField()" (blur)="compareEmail()"
                                                            placeholder="Re-enter Email Address" #autoFocus required />
                                                        <label for="" class="label">Re-Enter Email Address</label>
                                                        <!-- <i class="fa fa-close close" aria-hidden="true"></i> -->
                                                    </div>
                                                    <span *ngIf="(signupForm.controls.emailId2.touched && signupForm.controls.emailId2.dirty) 
                                        && (signupForm.controls.emailId.touched && signupForm.controls.emailId.dirty)
                                        && !isValidEmail" class="text-danger star">!</span>

                                                </div>
                                            </div>
                                            <span *ngIf="formSubmit && signupForm.get('emailId2')?.value===''"
                                                class="requiresLabel">
                                                This field is required.
                                            </span>
                                            <span
                                                *ngIf="!isValidEmail &&  (signupForm.controls.emailId2.touched || formSubmit) && signupForm.get('emailId2')?.value!='' && signupForm.get('emailId2')?.value!=null">
                                                <span class="msg" [innerHtml]="email2ErrMsg"></span>
                                            </span>
                                        </div>
                                        <div>
                                            <div id="phoneNo" style="display:flex;">
                                                <span class="star" style="color:#fff ;">*</span>
                                                <div class="inputContainer">
                                                    <div class="row">
                                                        <input type="text" class="input" formControlName="phone"
                                                            maxlength="14" (input)="phoneFormat($event)"
                                                            placeholder="Phone #" value={{phone}}
                                                            (blur)="validatePhone($event)" #autoFocus />
                                                        <label for="" class="label">Phone #</label>
                                                        <i class="fa fa-close close" aria-hidden="true"></i>
                                                    </div>

                                                </div>
                                            </div>
                                            <span
                                                *ngIf="(signupForm.controls.phone.errors?.['phoneValidation'])&&(!validPhNo)">
                                                <span class="msg"
                                                    *ngIf="signupForm.controls.phone.touched && signupForm.controls.phone.dirty && signupForm.get('phone')?.value!=''"
                                                    [innerHtml]="phnErrMsg">

                                                </span>
                                            </span>
                                        </div>
                                        <div>
                                            <div id="unitNo" style="display:flex;">
                                                <span class="text-danger star">*</span>
                                                <div class="inputContainer">
                                                    <div class="row">
                                                        <input type="text" maxlength="5"
                                                            (keypress)="keyFiveChar($event)" class="input"
                                                            formControlName="compassFacilityUnitNo"
                                                            (blur)="getCostCenter($event)"
                                                            placeholder="Compass Facility Unit Number" #autoFocus
                                                            required title="Please enter Valid user Id">
                                                        <label for="" class="label">Compass Facility/SAP Number</label>
                                                        <i class="fa fa-close close" aria-hidden="true"></i>
                                                    </div>

                                                </div>
                                            </div>
                                            <span
                                                *ngIf="formSubmit && signupForm.controls.compassFacilityUnitNo.errors?.['required']"
                                                class="requiresLabel">
                                                This field is required.
                                            </span>
                                            <span
                                                *ngIf="(signupForm.controls.compassFacilityUnitNo.errors?.['unitValidation'])">
                                                <span class="msg"
                                                    *ngIf="signupForm.controls.compassFacilityUnitNo.touched && signupForm.controls.compassFacilityUnitNo.dirty && signupForm.get('compassFacilityUnitNo')?.value!=''"
                                                    [innerHtml]="unitNoErrMsg">

                                                </span>
                                            </span>
                                        </div>
                                        <div>
                                            <div id="unitName" style="display:flex;">
                                                <span class="star" style="color:#fff ;">*</span>
                                                <div class="inputContainer">
                                                    <div class="row">
                                                        <input type="text" class="input"
                                                            formControlName="compassFacilityUnitName"
                                                            placeholder="Compass Facility Unit Name" #autoFocus
                                                            title="Please enter Valid user Id">
                                                        <label for="" class="label">Compass Facility Unit Name</label>
                                                        <i class="fa fa-close close" aria-hidden="true"></i>
                                                    </div>

                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <div class="row submit">
                                        <button type="submit" (click)="onSubmit()" name="submit"
                                            class="submitBtn btn btn-primary"
                                            [disabled]="signupForm.invalid">Submit</button>
                                    </div>


                                </div>

                            </div>


                        </div>
                    </div>

                </div>
            </form>
        </div>
    </div>
</div>